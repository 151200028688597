import React, { Component } from 'react';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import './App.scss';

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

class App extends Component {
  constructor() {
    super();
    this.campaign = 560444;
    this.donation_goal = 500000;
    this.state = { data: [], total: 0, donation: 0, goal: 0, needed: 0, donation_label: '', goal_label: '', needed_label: '' };
  }

  async componentDidMount() {
    this.setState({ total: this.donation_goal });
    this.getCampaignTotal();
    var intervalId = setInterval(this.getCampaignTotal.bind(this), 60000);
    this.setState({intervalId: intervalId});
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  async getBostonHauntedWalkTotal() {
    const res = await fetch( 'https://classyapi.crosby.co/api/boston_haunted_walk_2024', {
      method: "GET",
      mode: "cors"
    });

    return await res.json();
  }

  async getCampaignTotal() {
    let data = [];
    let campaign = await this.getBostonHauntedWalkTotal();

    let campaign_total_min = this.donation_goal *.05; 

    var campaign_total = campaign.total < campaign_total_min ? campaign_total_min : campaign.total;

    let donation = this.display_amount(campaign_total);
    let donation_label = this.display_label(campaign_total);
    let percent = ((campaign_total/this.donation_goal) * 100).toFixed(0);
    let percent_css = percent + '%';

    this.setState({ donation: donation });
    this.setState({ donation_label: donation_label });
    this.setState({ percent: percent });
    this.setState({ percent_css: percent_css });

  }

  display_amount(num) {
    if(num > 999 && num < 1000000){
      return (num/1000).toFixed(1); // convert to K for number from > 1000 < 1 million
    }else if(num >= 1000000){
      return (num/1000000).toFixed(2); // convert to M for number from > 1 million
    }else if(num < 1000){
      return num.toFixed(0); // if value < 1000, nothing to do
    }
  }

  display_label(num) {
    if(num > 999 && num < 1000000){
      return 'K'; // convert to K for number from > 1000 < 1 million
    }else if(num >= 1000000){
      return 'MM'; // convert to M for number from > 1 million
    }else if(num < 1000){
      return ''; // if value < 1000, nothing to do
    }
  }

  render() {
    return (
      <div className="donation-goals-data-circle">
        <CircularProgressbarWithChildren
          value={this.state.percent}
          background
          backgroundPadding={0}
          styles={buildStyles({
            textColor: "#000",
            pathColor: "#ffa62e",
            trailColor: "#C4C4C4",
            backgroundColor: "#ffffff"
          })}
          className="avia-animated-number-circle"
        >
          <div className="donation-goals-data-amount">
            <NumberFormat value={this.state.donation} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={false} prefix={'$'} suffix={this.state.donation_label} renderText={ (value, props) => <div className="amount">{value}</div>} />
          </div>
          <div className="donation-goals-data-amount-label">
            Our Goal<br/><NumberFormat value={this.state.total} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={false} prefix={'$'} renderText={ (value, props) => <div className="goal">{value}</div>} />
          </div>
        </CircularProgressbarWithChildren>
      </div> 
    );
  }
}

export default App;
